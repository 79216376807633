import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './LandingPage.css';

// Import all logos
import dechert from '../firm_logos/dechert.png';
import dlaPiper from '../firm_logos/dla-piper.png';
import cliffordChance from '../firm_logos/clifford-chance.png';
import kirklandEllis from '../firm_logos/kirkland-ellis.png';
import paulWeiss from '../firm_logos/paul-weiss.png';

const LandingPage = () => {
  const navigate = useNavigate();

  const firmLogos = [
    { src: cliffordChance, alt: 'Clifford Chance' },
    { src: kirklandEllis, alt: 'Kirkland & Ellis' },
    { src: paulWeiss, alt: 'Paul Weiss' },
    { src: dechert, alt: 'Dechert' },
    { src: dlaPiper, alt: 'DLA Piper' },
    // Duplicate logos for continuous scroll effect
    { src: cliffordChance, alt: 'Clifford Chance' },
    { src: kirklandEllis, alt: 'Kirkland & Ellis' },
    { src: paulWeiss, alt: 'Paul Weiss' },
    { src: dechert, alt: 'Dechert' },
    { src: dlaPiper, alt: 'DLA Piper' }
  ];

  return (
    <div className="landing-page">
      <header className="header-panel">
        <div className="header-content">
          <h1>TalentLex</h1>
          <Link to="/login" className="login-link">
            Already have an account? Log in here
          </Link>
        </div>
      </header>

      <main className="landing-main">
        <h2 className="main-heading">
          Our AI tells you why your vacation scheme application's been rejected.
        </h2>

        <div className="features-container">
          <div className="feature-box">
            <div className="feature-content">
              <h3>Draft and review</h3>
              <p>
                Personalise your applications and optimise their scores before you submit.
              </p>
            </div>
            <button
              onClick={() => navigate('/signup')}
              className="primary-button"
            >
              Get Started
            </button>
          </div>

          <div className="feature-box">
            <div className="feature-content">
              <h3>Track your application status</h3>
              <p>
                Download the Chrome extension to track when others are getting interviews from firms.
              </p>
            </div>
            <a
              href="https://chromewebstore.google.com/detail/talentlex-extension/eillgeanejhcfgibpllnijadpkpmfmmg"
              target="_blank"
              rel="noopener noreferrer"
              className="secondary-button"
            >
              Download Chrome Extension
            </a>
          </div>
        </div>

        <div className="logo-slider">
          <div className="logo-slide-track">
            {firmLogos.map((logo, index) => (
              <div key={index} className="logo-slide">
                <img
                  src={logo.src}
                  alt={logo.alt}
                  className="firm-logo"
                />
              </div>
            ))}
          </div>
        </div>

        <section className="faq-section">
          <h3>FAQs</h3>
          <div className="faq-list">
            <div className="faq-item">
              <h4>What is TalentLex?</h4>
              <p>
                TalentLex is a project that mimics how legal recruiters screen vacation scheme and training contract applications. We were frustrated at the lack of feedback in the process and believed people should be told how likely their application is to succeed.
              </p>
            </div>
            <div className="faq-item">
              <h4>Will firms be able to see that I've used TalentLex?</h4>
              <p>
                No, your data is secured. Please see our Privacy Policy for further information.
              </p>
            </div>
            <div className="faq-item">
              <h4>How does the model review applications?</h4>
              <p>
                We engineered prompts to infer the patterns that make up successful and unsuccessful applications. We take LLM hallucination seriously and do rigorous A/B testing.
              </p>
            </div>
          </div>
        </section>
      </main>

      <footer className="footer">
        <div className="footer-content">
          <p>© TalentLex Inc 2024</p>
          <a
            href="https://www.linkedin.com/company/talentlexai/"
            target="_blank"
            rel="noopener noreferrer"
            className="linkedin-link"
          >
            LinkedIn
          </a>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;